<template>
  <div class="user">
    <div class="title">C端用户管理</div>
    <ym-table-list :data="data"
                   :columns="columns"
                   operatorWidth="100px"
                   enableOperatorSlot
                   :pageLayout="pageLayout"
                   :total="total"
                   :pageNumber="page"
                   @handlePageJump="handlePageJump">

      <template #topControl>
        <div class="top-control clearfix">
          <!-- 选择的状态条件标签 -->
          <div class="top-control-add">
            <el-button type="primary"
                       @click="showModal(null,'showGroup')">一键发券</el-button>
          </div>
          <div class="top-control-add">
            <el-button type="primary"
                       @click="downLoad">下载发券模板</el-button>
          </div>
          <!-- 顶部右侧搜索，刷新和新增 -->
          <div class="top-control-refresh">
            <el-button icon="el-icon-refresh"
                       @click="handleRefresh">
            </el-button>
          </div>
          <div class="top-control-search">
            <el-input v-model="search"
                      placeholder="搜索联系电话"
                      prefix-icon="el-icon-search"
                      clearable
                      @clear="handleRefresh"
                      @keyup.enter.native="handleSearch">
            </el-input>
          </div>

        </div>
      </template>

      <template #lastConfirmTime="{row}">
        <template v-if="row.lastConfirmTime">{{row.lastConfirmTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}</template>
        <template v-else>-</template>
      </template>

      <template #operatorCol="{row}">
        <!-- <el-button class="operator-btn ym-icon-liulan"
                   @click.stop="showDetail(row)">详情</el-button> -->

        <!-- 测试用 -->
        <el-button class="operator-btn ym-icon-liulan"
                   @click.stop="showChainInfo(row)">账户</el-button>
        <!-- 测试用 -->

      </template>

    </ym-table-list>

    <send-group v-if="showGroup"
                :rowData="rowData"
                @hide="hiddenModal('showGroup')"
                @submit="submitGroup"></send-group>
    <send-progress v-if="showProgress"
                   :flagId="flagId"
                   @hide="hiddenModal('showProgress')"></send-progress>

    <!-- 测试用 -->
    <chain-info v-if="showInfo"
                :hashInfo="hashInfo"
                @CloseChainInfo="closeChainInfo">
      <template #hashTitle>
        <span>账户</span>
      </template>
      <template #hashInfo>
        <div>流通账户：{{hashInfo.currentAccountHash}}</div>
        <div>已核销账户：{{hashInfo.verificationAccountHash}}</div>
      </template>
    </chain-info>
    <!-- 测试用 -->

  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

import sendGroup from '@/views/accountManagement/sendTicket/sendGroup.vue'
import sendProgress from '@/views/accountManagement/sendTicket/sendProgress.vue'

import { fileDownload } from '@/utils/fileDownload.js'

// 测试用
import ChainInfo from '../chainInfo/index.vue'
import chainInfoMixin from '../chainInfo/chainInfo.js'
// 测试用

export default {

  // 测试用
  mixins: [chainInfoMixin],
  // 测试用

  components: {
    YmTableList, sendGroup, sendProgress, ChainInfo
  },
  data() {
    return {
      // 列表数据项
      data: [],
      // 列表表头配置项
      columns: [
        {
          label: '联系电话',
          prop: 'phone',
          align: 'center'
        },
        {
          label: '持有总数(张)',
          prop: 'remainderQuantity',
          align: 'center'
        },

        {
          label: '已核销(张)',
          prop: 'confirmQuantity',
          align: 'center'
        },
        {
          label: '最近一次核销时间',
          prop: 'lastConfirmTime',
          align: 'center',
          enableColSlot: true,
          width: '200px'
        }
      ],
      pageLayout: 'prev, pager, next, jumper',
      size: 10,
      page: 1,
      showGroup: false,
      rowData: {},
      excelUrl: window.BASE_URL + '/static/file/ae90f62e05df207e5908d2a790436e03.xlsx',
      total: 10, // 数据总数
      search: '', // 搜索内容
      showProgress: false,
      flagId: '',
      timer: null
    }
  },
  watch: {

  },
  mounted() {
    this.getList()
  },
  methods: {
    // 对搜索框及状态选择标签进行搜索
    handleSearch() {
      this.page = 1
      this.getList()
    },
    // 清空搜索条件(包括状态选择和搜索文本)
    handleRefresh() {
      this.search = ''
      this.handleSearch()
    },
    // 处理页码跳转
    handlePageJump(page, pageSize) {
      this.page = page
      this.getList()
    },
    getList() {
      this.$http.user.userTicketList({
        size: this.size,
        page: this.page,
        phone: this.search
      }).then(res => {
        if (res.data.code === 0) {
          this.data = res.data.data.records
          this.total = res.data.data.total
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    hiddenModal(type) {
      this.$bus.$emit('clearBlur')
      this[type] = false
      this.getList()
    },
    submitGroup(form, rowData) {
      this.$http.ticket.userTicketsSend({
        ticketId: form.type,
        quantity: form.number,
        excelFileUrl: window.BASE_URL + form.file
      }).then(res => {
        if (res.data.code === 0) {
          this.hiddenModal('showGroup')
          this.flagId = res.data.data
          clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            this.showProgress = true
          }, 200)
        }
      })
    },
    showModal(row, type) {
      this.$bus.$emit('blurScreen')
      this.rowData = row
      this[type] = true
    },
    downLoad() {
      fileDownload(this.excelUrl, '发券模板', 'xlsx')
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="scss">
.user {
  .title {
    font-size: 20px;
    font-weight: 600;
    color: $--color-text-primary;
    margin-bottom: 30px;
  }
  .top-control {
    margin-top: 16px;
    margin-bottom: 16px;
    .dropdownlist-tag {
      float: left;
      margin-left: 32px;
      .el-tag {
        position: relative;
        width: 110px;
        height: 40px;
        font-size: 14px;
        line-height: 41px;
        background-color: $--color-primary-light-9;
        border: none;
        border-radius: 6px;
        color: $--color-text-primary;
        padding-left: 20px;
        padding-right: 11px;
        cursor: default;
        .el-icon-close {
          position: absolute;
          right: 11px;
          top: 50%;
          margin-top: -8px;
          color: $--color-text-primary;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
    &-search {
      float: right;
      width: 240px;
      margin-right: 24px;
      .el-input {
        border-radius: 6px;
      }
    }
    &-refresh {
      float: right;
      margin-right: 24px;
      .el-button {
        padding: 8px 11px;
        .el-icon-refresh::before {
          font-size: 20px;
        }
      }
    }
    &-add {
      float: right;
      margin-right: 30px;
      font-size: $--font-size-medium;
      font-weight: $--font-weight-primary;
    }
  }
  .dropdownlist {
    padding-top: 19px;
    padding-bottom: 19px;
    border-top: 1px solid #e5e8f3;
    .el-button {
      width: 90px;
      height: 41px;
      margin-left: 32px;
    }
  }
}
.tag {
  margin-right: 10px;
}
</style>
